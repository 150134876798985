import React from "react";
import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Page } from "../layout/page";
import { Parallax } from "../layout/parallax";
import { AnimateIn } from "../layout/animate_in";

import * as css from "./about.module.scss";
import PhoneIcon from "../images/icon-phone.svg";
import EmailIcon from "../images/icon-email.svg";

export default function IndexPage(props: PageProps) {
  return (
    <Page menuId="about" title="About">
      <div className={css.about}>
        <h2>About</h2>
        <div className={css.intro}>
          We are a dynamic team of nano pharmaceutical scientists and manufacturing engineers with
          expertise in advanced nano drug delivery technologies, scale up manufacturing, pharmacy
          operations and pharmaceutical distribution. Together, we are pushing the boundaries in
          state-of-the-art pharmaceutical manufacturing while radically transforming the standards
          of medical efficacy and patient care.
        </div>
        <div className={css.photoContainer}>
          <Parallax z={0} mz={0.2}>
            <StaticImage className={css.photo} src="../images/about.jpg" alt="" />
          </Parallax>
        </div>
      </div>
      <hr className={css.short} />
      <div className={css.contact}>
        <h3>Contacts</h3>
        <div className={css.contactsContainer}>
          <div className={css.left}>
            <a href="mailto:info@cnano.ca">
              <EmailIcon className={css.icon} /> info@cnano.ca
            </a>
          </div>
          <div className={css.right}>
            <a href="tel:6047205545">
              <PhoneIcon className={css.icon} /> (604) 720-5545
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
}
